

export const utilities = (token, get_token_values) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    const decodedToken = JSON.parse(jsonPayload);
    if (decodedToken.admin) {
        get_token_values(decodedToken.admin)
    }

}

export default utilities