import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../redux/Authentication/AuthSlice";

import courierReducer from "./courier/courierSlice";
import employeeReducer from './employee/employeeSlice'
import courier_dispatchReducer from './courier_dispatch/Courier_Dispatch'
import transactionReducer from './transactions/TransactionSlice'
export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    courier: courierReducer,
    employee: employeeReducer,
    courier_dispatch: courier_dispatchReducer,
    transaction: transactionReducer,
  },
});
