import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { courierPost } from "../../redux/courier/courierSlice";
import { CustomTextField } from "../../components/CustomeField";

const Add_Admin = () => {

  const [sendername, setSenderName] = useState("");
  const [sendermobile, setSenderMobile] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderZipCode, setSenderZipCode] = useState("");
  const [recipientname, setRecipientName] = useState("");
  const [recipientmobile, setRecipientMobile] = useState("");
  const [recipientCity, setRecipientCity] = useState("");
  const [recipientState, setRecipientState] = useState("");
  const [recipientZipCode, setRecipientZipCode] = useState("");
  const [recipientaltmobile, setRecipientAltMobile] = useState("");
  const [description_of_couriers, setDescription_of_couriers] = useState("");
  const [dimenstions, setDimenstions] = useState("");
  const [handling_instruction, setHandling_instruction] = useState("");
  const [prefered_delivery_date_And_time, setPrefered_delivery_date_And_time] = useState("");
  const [pickup_dateNtime, setPickup_dateNTime] = useState("");
  const [payment_method, setPaymentMethod] = useState("");
  const [payment_reference_id, setPayment_Refrence_Id] = useState("");
  // const [tracking_Id, setTracking_Id] = useState("");
  const [billing_Address, setBilling_Address] = useState("");
  const [date, setDate] = useState("");
  const [employee_id, set_Employee_Id] = useState("")
  const [shop, setShop] = useState("");
  const [assign_city, setAssgn_City] = useState("");
  const [assign_address, setAssgn_Address] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const dispatch = useDispatch()
  let handleSubmit = async (e) => {
    e.preventDefault()
    const formData = {
      sender_name: sendername,
      sender_phoneNumber: sendermobile,
      sender_address: {
        city: senderCity,
        state: senderState,
        zip_code: senderZipCode,

      },
      recipient_name: recipientname,
      recipient_phonenumber: recipientmobile,
      recipient_alt_phoneNumber: recipientaltmobile,
      recipient_address: {
        city: recipientCity,
        state: recipientState,
        zip_code: recipientZipCode
      },
      description_of_couriers: description_of_couriers,
      dimensions: dimenstions,
      handling_instruction: handling_instruction,
      preferred_delivery_dataNtime: prefered_delivery_date_And_time,
      pickup_dateNtime: pickup_dateNtime,
      payment_method: payment_method,
      payment_reference_id: payment_reference_id,
      billing_address: billing_Address,
      // assign_to: [
      //   {
      //     employee_id: employee_id,
      //     shop_name: shop,
      //     city: assign_city,
      //     address: assign_address,
      //     date: date
      //   }
      // ],
      weight: weight,
      price: price
    }
    console.log(formData, 'formdata');
    const dataPost = await dispatch(courierPost(formData))
    console.log(dataPost.payload);
    if (dataPost.payload.success) {
      alert('Courier has been Created')
      setSenderName("")
      setSenderMobile("")
      setSenderCity("")
      setSenderState("")
      setSenderZipCode("")
      setRecipientName("")
      setRecipientMobile("")
      setRecipientCity("")
      setRecipientState("")
      setRecipientZipCode("")
      setRecipientAltMobile("")
      setDescription_of_couriers("")
      setDimenstions("")
      setHandling_instruction("")
      setPrefered_delivery_date_And_time("")
      setPickup_dateNTime("")
      setPaymentMethod("")
      setPayment_Refrence_Id("")
      setBilling_Address("")
      setDate("")
      set_Employee_Id("")
      setShop("")
      setAssgn_City("")
      setAssgn_Address("")
      setWeight("")
      setPrice("")
    }
  }

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Courier Details</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div>
            <h5>Sender Details</h5>
            <div className="inputs_container">
              <div className="dn_input_box">
                <CustomTextField
                  style={{ fontSize: "1rem", fontWeight: "500" }}
                  label="Name"
                  type="text"
                  id="text"
                  className="input_style"
                  value={sendername}
                  onChange={(e) => setSenderName(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Mobile No"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={sendermobile}
                  onChange={(e) => setSenderMobile(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="City"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}
                  value={senderCity}
                  onChange={(e) => setSenderCity(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="State"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={senderState}
                  onChange={(e) => setSenderState(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Zip Code"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={senderZipCode}
                  onChange={(e) => setSenderZipCode(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
            </div>
            <h5>Recipient Details:</h5>
            <div className="inputs_container">
              <div className="dn_input_box">
                <CustomTextField
                  label="Name"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={recipientname}
                  onChange={(e) => setRecipientName(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Mobile Number"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}
                  value={recipientmobile}
                  onChange={(e) => setRecipientMobile(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="City"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={recipientCity}
                  onChange={(e) => setRecipientCity(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="State"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={recipientState}
                  onChange={(e) => setRecipientState(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Zip Code"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={recipientZipCode}
                  onChange={(e) => setRecipientZipCode(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Alternative Mobile Number"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={recipientaltmobile}
                  onChange={(e) => setRecipientAltMobile(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
            </div>

            <h5>Courier Details</h5>

            <div className="inputs_container">
              <div className="dn_input_box">
                <CustomTextField
                  label="description of Couriers"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={description_of_couriers}
                  onChange={(e) => setDescription_of_couriers(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Price"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Dimensions h*w*d"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={dimenstions}
                  onChange={(e) => setDimenstions(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Weight"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Handling instructions"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={handling_instruction}
                  onChange={(e) => setHandling_instruction(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Prefered delivery date and time"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}
                  value={prefered_delivery_date_And_time}
                  onChange={(e) => setPrefered_delivery_date_And_time(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Pickup date and time"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={pickup_dateNtime}
                  onChange={(e) => setPickup_dateNTime(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Payment Method"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={payment_method}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Payment Reference Id"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={payment_reference_id}
                  onChange={(e) => setPayment_Refrence_Id(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Billing address"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={billing_Address}
                  onChange={(e) => setBilling_Address(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
            </div>


            <h5>Assign To :</h5>
            <div className="inputs_container">
              <div className="dn_input_box">
                <CustomTextField
                  label="Employee Id"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}
                  value={employee_id}
                  onChange={(e) => set_Employee_Id(e.target.value)}

                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Shop Name"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={shop}
                  onChange={(e) => setShop(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="City"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={assign_city}
                  onChange={(e) => setAssgn_City(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Addresss"
                  type="text"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={assign_address}
                  onChange={(e) => setAssgn_Address(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
              <div className="dn_input_box">
                <CustomTextField
                  label="Date"
                  type="date"
                  id="text"
                  className="input_style"
                  // error={nameError !== "" ? true : false}
                  // helperText={nameError}

                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  autocomplete="off"
                  size="small"
                />
                <p style={{ color: "red" }} className="mt-2">
                </p>
              </div>
            </div>




          </div>
          <div className="card-footer ">
            {
              price != '' && weight !== '' && sendername !== "" &&
                sendermobile !== "" && senderCity !== "" && senderState !== "" &&
                senderZipCode !== "" && recipientname !== "" && recipientmobile !== "" &&
                recipientCity !== "" && recipientState !== "" && recipientZipCode !== "" && recipientaltmobile !== "" &&
                description_of_couriers !== "" && dimenstions !== "" && handling_instruction !== "" &&
                prefered_delivery_date_And_time !== "" && pickup_dateNtime !== "" && payment_method !== "" &&
                payment_reference_id !== "" && billing_Address !== "" && weight !== "" && price !== ''
                ? (
                  <button className="btn btn-primary" type="submit" >
                    Add Courier
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit" disabled>
                    Add Courier
                  </button>
                )
            }

          </div>
        </div>
      </div>


    </>
  );
};

export default Add_Admin;
