import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import Checkbox from "@mui/material/Checkbox";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button, Flex, Modal, message } from "antd";
import { FaEdit, FaBell } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { totalAdmins } = useSelector((store) => store.auth);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [fetchData, setFetchData] = useState([]);
  const [filter_data, setfilter_data] = useState([]);
  const [export_data, setexport_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");
  const [loadData, setLoadData] = useState([]);
  const [selectBrand, setSelectBrand] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const { employeeList } = useSelector((store) => store.employee);

  useEffect(() => {
    setLoadData(employeeList);
  }, [employeeList]);
  useEffect(() => {
    let filter_trans = employeeList;
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return transaction.name.toLowerCase().match(search.toLowerCase());
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date, employeeList]);

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (id) => {
    navigate(`/admin/update-courier-point/${id}`);
  };
  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Search Courier Points</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="transaction_header">
          <Input
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
            }}
          />
          <TablePagination
            component="div"
            count={0}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 100, 500]}
            labelRowsPerPage="Rows:"
            labelDisplayedRows={() => null}
            SelectProps={{
              inputProps: { "aria-label": "rows" },
              native: true,
            }}
            ActionsComponent={() => null}
          />
        </div>
        <div className="letter_content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} className="customScrollbar">
              <Table
                sx={{ minWidth: 700, border: "none" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ border: "none" }}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Mobile
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      City
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      State
                    </StyledTableCell>

                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter_data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
                    .map((admin, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ border: "none" }}
                          width={50}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          width={250}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {admin.name.length > 30 ? (
                            <>{admin.name.slice(0, 30)}...</>
                          ) : (
                            <>{admin.name.slice(0, 30)}</>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          width={100}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {admin.phone}
                        </StyledTableCell>
                        <StyledTableCell
                          width={200}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {admin.email}
                        </StyledTableCell>
                        <StyledTableCell
                          width={100}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {admin.pointAddress.city}
                        </StyledTableCell>
                        <StyledTableCell
                          width={150}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {admin.pointAddress.state}
                        </StyledTableCell>
                        <StyledTableCell
                          width={100}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          <div className="d-flex m-auto my-2">
                            <Button
                              onClick={() => handleClick(admin._id)}
                              variant="contained"
                              color="primary"
                              className="edit_button"
                            >
                              EDIT
                            </Button>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 100, 500]}
              component="div"
              count={filter_data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};
