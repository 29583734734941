import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
    courier_dispatch_list: localStorage.getItem("courier_dispatch_list")
        ? JSON.parse(localStorage.getItem("courier_dispatch_list"))
        : [],
    courier_dispatch_listLoading: true,
    iscourier_dispatch_listAvailable: localStorage.getItem("courier_dispatch_list")
        ? true
        : false,
    isLoading: false,

};

export const courier_dispatchPost = createAsyncThunk(
    "courier_dispatch/courier_dispatchPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "counter-type": "application/json",
                },

            };
            const url = `${Baseurl}/api/v1/courier-dispatch/new`
            const resp = await axios.post(url, formData, config);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Courier not created")
        }

    }
)

export const getCourier_dispatchList = createAsyncThunk(
    "courier_dispatch/getCourier_dispatchList",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/courier-dispatch/all`;
            const resp = await axios.get(url)
            return resp.data.courierDispachHistory;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 not found")
        }
    }
)


const courier_dispatchSlice = createSlice({
    name: "courier_dispatch",
    initialState,
    reducers: {
        setRemoveCou_dispData(state, action) {
            state.courier_dispatch_list = [];
            localStorage.setItem("courier_dispatch_list", JSON.stringify(state.courier_dispatch_list));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(courier_dispatchPost.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(courier_dispatchPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.isLoading = false;
                    state.courier_dispatch_list = [action.payload.courierDispachHistory, ...state.courier_dispatch_list]
                    localStorage.setItem("courier_dispatch_list", JSON.stringify(state.courier_dispatch_list));
                }
            })
            .addCase(courier_dispatchPost.rejected, (state) => {
                state.isLoading = true
            })

            .addCase(getCourier_dispatchList.pending, (state) => {
                state.courier_dispatch_listLoading = true
            })
            .addCase(getCourier_dispatchList.fulfilled, (state, action) => {
                state.courier_dispatch_list = action.payload
                localStorage.setItem("courier_dispatch_list", JSON.stringify(state.courier_dispatch_list));
                state.iscourier_dispatch_listAvailable = true;
                state.courier_dispatch_listLoading = false;
            })
            .addCase(getCourier_dispatchList.rejected, (state) => {
                state.courier_dispatch_listLoading = true;
            })

    },
});
export const {
    setRemoveCou_dispData,
} = courier_dispatchSlice.actions;
export default courier_dispatchSlice.reducer;

