import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Cookies from "js-cookie";

export const create_jsw_token = createAsyncThunk(
  'auth/create_jsw_token',
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        }
      };
      const url = `${Baseurl}/api/v1/admin/login-verify-with-JWS`
      const resp = await axios.post(url, formData, config)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue("Employee is not created ")
    }
  }

)



const initialState = {
  isAuth: Cookies.get('login_token') ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin(state, action) {
      state.isAuth = true;
    },
    signout(state, action) {
      state.isAuth = false;
      Cookies.remove('login_token');
    },
  },
  extraReducers: (builder) => {

  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
