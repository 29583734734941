import React, { useState, useEffect } from "react";
import { FaMoneyBill, FaAngleDoubleRight } from "react-icons/fa";
import { Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const Dashboard = () => {
  const navigate = useNavigate();
  const { employeeList } = useSelector((store) => store.employee);
  const { courierPickedList, totalcourierList } = useSelector(
    (store) => store.courier
  );
  const { transaction, total_money } = useSelector(
    (state) => state.transaction
  );
  const { courier_dispatch_list } = useSelector(
    (state) => state.courier_dispatch
  );
  return (
    <>
      <div className="main_fragnent_container">
        <div className="main_amount_container">
          <div className="mount_container_left">
            <div className="amount_box lnbackg2">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>{employeeList.length}</h5>
                    <span>Total Points</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(employeeList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg3">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>{totalcourierList.length}</h5>
                    <span>Total Couriers</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>{courierPickedList.length}</h5>
                    <span>Total Picked Up</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg4">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>0</h5>
                    <span>Total Delivered</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg2">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>0</h5>
                    <span>Total Returned</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>{total_money}</h5>
                    <span>Total Earner</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg4">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>{courier_dispatch_list.length}</h5>
                    <span>Total History</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>
                    {/* LAST UPDATED AT{" "}
                    {moment(totalcourierList[0].createdAt).format(
                      "YYYY-MM-DD h:mm A"
                    )} */}
                  </span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
