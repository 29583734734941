import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: "purple", // Text color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "purple", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "green", // Border color on hover
      borderWidth: "0.3vh",
    },
    "&.Mui-focused fieldset": {
      borderColor: "purple", // Border color when focused
    },
  },
  "& .MuiInputLabel-root": {
    color: "purple", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "purple", // Label color when focused
  },
}));
