
import React, { useState, useEffect } from "react";
import { CustomTextField } from "../../components/CustomeField";
import { useDispatch, useSelector } from "react-redux";
import {
  employeePost,
  get_single_employee,
  updateEmployee,
  validate_Employee_Aadhar,
  validate_Employee_Email,
  validate_Employee_Number,
  validate_Employee_Pancard,
} from "../../redux/employee/employeeSlice";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { message, Select, Input } from "antd";
import { MenuItem, Chip } from "@mui/material";
import countriesdata from "../json_data/countries.json";
import citiesdata from "../json_data/states+cities.json";
import statesdata from "../json_data/countries+states.json";
import statesandcityandcountrydata from "../json_data/countries+states+cities.json";
import diistrics from "../json_data/states-and-districts.json";
import pincodes from "../json_data/pincodes.json";
import Addpincodes from "./component/Addpincodes";
import { useParams } from "react-router-dom";
const { Option } = Select;
export const EditEmployee = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { websiteSetting } = useSelector((store) => store.employee);
  const [name, setName] = useState("");
  const [shop, setShop] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobile_Confirm, setMobile_Confirm] = useState("");
  const [Email_Confirm, setEmail_Confirm] = useState("");
  const [email, setEmail] = useState("");
  const [pancard, setPancard] = useState("");
  const [Pan_Card_No_conirm, setPan_Card_No_Confirm] = useState("");
  const [Aadhaar_No_confirm, setAadhaar_No_confirm] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [pointhouseNumber, setPointhouseNumber] = useState("");
  const [pointArea, setPointArea] = useState("");
  const [pointcity, setPointCity] = useState("");
  const [pointdistrict, setPointDistrict] = useState("");
  const [pointState, setPointState] = useState("");
  const [pointCountry, setPointCountry] = useState("");
  const [pointPincode, setPointPincode] = useState("");
  const [point_type, setpoint_type] = useState("");

  const [add_courier_status, setadd_courier_status] = useState(false);

  ///errorrrsss
  const [nameError, setNameError] = useState("");
  const [shopError, setShopError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pancardError, setPancardError] = useState("");
  const [aadharCardError, setAadharCardError] = useState("");

  const [pointhouseNumberError, setPointhouseNumberError] = useState("");
  const [pointAreaError, setPointAreaError] = useState("");
  const [pointcityError, setPointCityError] = useState("");
  const [pointdistrictError, setPointDistrictError] = useState("");
  const [pointStateError, setPointStateError] = useState("");
  const [pointCountryError, setPointCountryError] = useState("");
  const [pointPincodeError, setPointPincodeError] = useState("");
  const [point_typeError, setpoint_typeError] = useState("");
  const [roleError, setroleError] = useState("");
  const [deliverypointError, setdeliverypointError] = useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [districtByState, setdistrictByState] = useState([]);
  const [citiersByStates, setcitiersByStates] = useState([]);
  const [PincodeByDistric, setPincodeByDistric] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [uploadeRoles, setuploadeRoles] = useState({
    Warehouse: false,
    Main_Hub: false,
    AirPort_Transit_Point: false,
    Delivery_Point: false,
    Local_Hub: false,
    Bus_Stop_Transit_Point: false,
    Railway_Transit_Point: false,
    Add_Courier: false,
  });
  const [selectedPincodes, setSelectedPincodes] = useState([]);


  useEffect(() => {
    if (params.id) {
      const fetch_employee = async () => {
        const get_single = await dispatch(get_single_employee(params.id));
        if (get_single.payload.success) {
          const employee = get_single.payload.emplayee;
          setName(employee.name);
          setMobile(employee.phone);
          setMobile_Confirm(employee.phone);
          setEmail(employee.email);
          setEmail_Confirm(employee.email);
          setPancard(employee.pancard);
          setPan_Card_No_Confirm(employee.pancard);
          setShop(employee.shop);
          setAadharCard(employee.aadharCard);
          setAadhaar_No_confirm(employee.aadharCard);
          setpoint_type(employee.point_type);

          setadd_courier_status(employee.add_courier_status);
          setPointhouseNumber(employee.pointAddress.houseNumber);
          setPointArea(employee.pointAddress.area);
          setPointCity(employee.pointAddress.city);
          setPointDistrict(employee.pointAddress.district);
          setPointState(employee.pointAddress.state);
          setPointCountry(employee.pointAddress.country);
          setPointPincode(employee.pointAddress.pincode);

          // setuploadeRoles(employee.roles);
          setSelectedPincodes(employee.delivering_pincodes);

          const keysWithTrueValues = Object.keys(employee.roles).filter(
            (key) => employee.roles[key]
          );
          setSelectedRoles(keysWithTrueValues);
        }
      };
      fetch_employee();
    }
  }, [params.id]);


  useEffect(() => {
    if (pointState !== "") {
      const distric_find_by_state = diistrics.find((data) =>
        data.state.toLowerCase().match(pointState.toLowerCase())
      );
      setdistrictByState([...distric_find_by_state.districts]);
    }
    if (pointState !== "") {
      const cities_find_by_state = citiesdata.find(
        (data) => data.name.toLowerCase() === pointState.toLowerCase()
      );
      setcitiersByStates([...cities_find_by_state.cities]);
    }

    if (pointdistrict) {
      const filterpin = pincodes.filter(
        (data) =>
          data.districtName.toLowerCase().match(pointdistrict.toLowerCase()) ||
          data.districtName.toLowerCase() === pointdistrict.toLowerCase()
      );

      const filterUniquePincode = (array) => {
        const seen = new Set();
        return array.filter((item) => {
          const duplicate = seen.has(item.pincode);
          seen.add(item.pincode);
          return !duplicate;
        });
      };
      const uniqueItemsArray = filterUniquePincode(filterpin);
      setPincodeByDistric([...uniqueItemsArray]);
    }
    if (selectedRoles.length > 0) {
      let roles = uploadeRoles;
      const rolesss = [...selectedRoles];
      for (let index = 0; index < rolesss.length; index++) {
        let element = rolesss[index];
        const keys = Object.keys(roles);
        if (keys.includes(element)) {
          roles = {
            ...roles,
            [element]: true,
          };
        } else {
        }
      }
      setuploadeRoles(roles);
    }
  }, [pointState, pointdistrict, selectedRoles]);

  useEffect(() => {
    if (btnPress === true) {
      if (mobile_Confirm.length !== 10) {
        setMobileError("Mobile number must be 10 char length");
      } else {
        setMobileError("");
      }
      if (name === "") {
        setNameError("Required");
      } else {
        setNameError("");
      }
      if (shop === "") {
        setShopError("Required");
      } else {
        setShopError("");
      }
      if (Email_Confirm === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
      if (Pan_Card_No_conirm.length !== 10) {
        setPancardError("Pancard number must be 10 char length");
      } else {
        setPancardError("");
      }
      if (Aadhaar_No_confirm.length !== 12) {
        setAadharCardError("Aadhar card number must be 12 char length");
      } else {
        setAadharCardError("");
      }

      if (pointhouseNumber === "") {
        setPointhouseNumberError("Required");
      } else {
        setPointhouseNumberError("");
      }
      if (pointArea === "") {
        setPointAreaError("Required");
      } else {
        setPointAreaError("");
      }
      if (pointcity === "") {
        setPointCityError("Required");
      } else {
        setPointCityError("");
      }
      if (pointdistrict === "") {
        setPointDistrictError("Required");
      } else {
        setPointDistrictError("");
      }
      if (pointState === "") {
        setPointStateError("Required");
      } else {
        setPointStateError("");
      }
      if (pointCountry === "") {
        setPointCountryError("Required");
      } else {
        setPointCountryError("");
      }

      if (pointPincode.length !== 6) {
        setPointPincodeError("Zip Code must be 6 char length");
      } else {
        setPointPincodeError("");
      }
      if (selectedRoles.length === 0) {
        setroleError("Required");
      } else {
        setroleError("");
      }
      if (selectedRoles.includes("Delivery_Point")) {
        if (selectedPincodes.length === 0) {
          setdeliverypointError("Required");
        } else {
          setdeliverypointError("");
        }
      }
    }
  }, [
    btnPress,
    name,
    Email_Confirm,
    mobile_Confirm,
    Aadhaar_No_confirm,
    Pan_Card_No_conirm,
    shop,
    pointhouseNumber,
    pointArea,
    pointcity,
    pointdistrict,
    pointState,
    pointCountry,
    pointPincode,
    selectedRoles,
    selectedPincodes,
  ]);
  const Select_next_point = [
    {
      label: "Main Hub",
      value: "Main_Hub",
      status: false,
    },
    {
      label: "Warehouse",
      value: "Warehouse",
      status: false,
    },
    {
      label: "AirPort Transit Point",
      value: "AirPort_Transit_Point",
      status: false,
    },
    {
      label: "Bus Stop Transit Point",
      value: "Bus_Stop_Transit_Point",
      status: false,
    },
    {
      label: "Railway Transit Point",
      value: "Railway_Transit_Point",
      status: false,
    },
    {
      label: "Local Hub",
      value: "Local_Hub",
      status: false,
    },
    {
      label: "Delivery Point",
      value: "Delivery_Point",
      status: false,
    },
    {
      label: "Add Courier",
      value: "Add_Courier",
      status: false,
    },
  ];

  const validateEmail = async (e) => {
    const inputValue = e.target.value;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setEmail(inputValue);
    if (!emailRegex.test(inputValue)) {
      setEmailError("*Error! you have entered invalid email.");
    } else {
      const verifyemail = await dispatch(validate_Employee_Email(inputValue));
      if (verifyemail.payload.success === true) {
        setEmailError("Email already exits");
      } else {
        setEmail_Confirm(inputValue);
        setEmailError("");
      }
    }
  };

  const validateMobile = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setMobile(inputValue);
    if (inputValue.length !== 10) {
      setMobileError("*Error! you have entered invalid mobile number.");
    } else {
      const verify_number = await dispatch(
        validate_Employee_Number(inputValue)
      );
      if (verify_number.payload.success === true) {
        setMobileError("Mobile number already exits");
        // setmobile("");
      } else {
        setMobile_Confirm(inputValue);
        setMobileError("");
      }
    }
  };

  const validatePancardno = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    const pancardRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    setPancard(inputValue);
    if (!pancardRegex.test(inputValue)) {
      setPancardError("*Error! you have entered invalid pan card.");
    } else {
      const verifyPancard = await dispatch(
        validate_Employee_Pancard(inputValue)
      );
      if (verifyPancard.payload.success === true) {
        setPancardError("pan card already exits");
      } else {
        setPan_Card_No_Confirm(inputValue);
        setPancardError("");
      }
    }
  };

  const validateAadharcard = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").toUpperCase();
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setAadharCard(inputValue);
    if (inputValue.length !== 12) {
      setAadharCardError("*Error! you have entered invalid Aadhar number.");
    } else {
      const verify_number = await dispatch(
        validate_Employee_Aadhar(inputValue)
      );
      if (verify_number.payload.success === true) {
        setAadharCardError("Aadhar number already exits");
        // setmobile("");
      } else {
        setAadhaar_No_confirm(inputValue);
        setAadharCardError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setbtnPress(true);
    setbtnLoading(true);

    let del_pincodes_status = false;

    if (selectedRoles.includes("Delivery_Point")) {
      if (selectedPincodes.length === 0) {
        del_pincodes_status = true;
      } else {
        del_pincodes_status = false;
      }
    } else {
      del_pincodes_status = false;
    }

    let main_point = "";
    if (uploadeRoles.Main_Hub === true) {
      main_point = "Main Hub";
    } else if (uploadeRoles.Warehouse === true) {
      main_point = "Warehouse";
    } else if (uploadeRoles.AirPort_Transit_Point === true) {
      main_point = "AirPort Transit Point";
    } else if (uploadeRoles.Bus_Stop_Transit_Point === true) {
      main_point = "Bus Stop Transit Point";
    } else if (uploadeRoles.Railway_Transit_Point === true) {
      main_point = "Railway Transit Point";
    } else if (uploadeRoles.Local_Hub === true) {
      main_point = "Local Hub";
    } else if (uploadeRoles.Delivery_Point === true) {
      main_point = "Delivery Point";
    } else if (uploadeRoles.Add_Courier === true) {
      main_point = "Add Courier";
    }

    if (
      name !== "" &&
      shop !== "" &&
      mobile_Confirm !== "" &&
      Email_Confirm !== "" &&
      Pan_Card_No_conirm !== "" &&
      Aadhaar_No_confirm !== "" &&
      pointArea !== "" &&
      pointcity !== "" &&
      pointdistrict !== "" &&
      pointState !== "" &&
      pointCountry !== "" &&
      selectedRoles.length !== 0 &&
      del_pincodes_status !== true &&
      pointPincode !== ""
    ) {
      const find_data = Select_next_point.find(
        (data) => data.label === main_point
      );
      const find_commission = Object.fromEntries(
        Object.entries(websiteSetting).filter(
          ([key, value]) => key === find_data.value
        )
      );
      const userArray = Object.entries(find_commission).map(([key, value]) => {
        const obj = {};
        obj[`key1`] = key;
        obj[`value`] = value;
        return obj;
      });
      const commission = userArray[0].value;
      const formData = {
        _id: params.id,
        name: name,
        phone: Number(mobile),
        password: mobile,
        email: email,
        commission: Number(commission),
        pancard: pancard,
        aadharCard: aadharCard,
        shop: shop,
        roles: uploadeRoles,
        delivering_pincodes: selectedPincodes,
        pointAddress: {
          houseNumber: pointhouseNumber,
          area: pointArea,
          city: pointcity,
          district: pointdistrict,
          state: pointState,
          country: pointCountry,
          pincode: pointPincode,
        },
      };
      const dataPost = await dispatch(updateEmployee(formData));
      if (dataPost.payload.success) {
        messageApi.open({
          type: "success",
          content: "Courier Point Updated done successfully",
        });
        setbtnPress(false);

      }
      setbtnLoading(false);
    } else {
      setbtnLoading(false);
    }
  };

  const filterOption = (input, option) =>
    option.label.toString().toLowerCase().includes(input.toLowerCase());

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const handleDelete = (value) => {
    const remove = selectedRoles.filter((data) => data !== value);
    setSelectedRoles([...remove]);
  };

  const handleSelect = (value) => {
    setSelectedRoles([...selectedRoles, value]);
  };

  const handleDeselect = (value) => {
    const remove = selectedRoles.filter((data) => data !== value);
    setSelectedRoles([...remove]);
  };
  const pincodeDelete = (value) => {
    const remove = selectedPincodes.filter((data) => data !== value);
    setSelectedPincodes([...remove]);
  };

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Courier Point</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div>
              <h5>Point Address :</h5>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Point Name"
                    type="text"
                    id="text"
                    error={shopError !== "" ? true : false}
                    helperText={shopError}
                    className="input_style"
                    value={shop}
                    onChange={(e) =>
                      setShop(
                        e.target.value.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                      )
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="House Number"
                    type="text"
                    id="text"
                    error={pointhouseNumberError !== "" ? true : false}
                    helperText={pointhouseNumberError}
                    className="input_style"
                    value={pointhouseNumber}
                    onChange={(e) =>
                      setPointhouseNumber(
                        e.target.value.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                      )
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    id="select"
                    label="Country"
                    select
                    error={pointCountryError !== "" ? true : false}
                    helperText={pointCountryError}
                    className="input_style"
                    value={pointCountry}
                    onChange={(e) => setPointCountry(e.target.value)}
                    size="small"
                  >
                    {countriesdata
                      .filter((data) => data.name === "India")
                      .map((data, index) => (
                        <MenuItem key={index} value={data.name}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </CustomTextField>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    id="select"
                    label="State"
                    select
                    error={pointStateError !== "" ? true : false}
                    helperText={pointStateError}
                    className="input_style"
                    value={pointState}
                    onChange={(e) => setPointState(e.target.value)}
                    size="small"
                  >
                    {statesdata
                      .find((data) => data.name === "India")
                      .states.map((data, index) => (
                        <MenuItem key={index} value={data.name}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </CustomTextField>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    id="select"
                    label="District"
                    select
                    error={pointdistrictError !== "" ? true : false}
                    helperText={pointdistrictError}
                    className="input_style"
                    value={pointdistrict}
                    onChange={(e) => setPointDistrict(e.target.value)}
                    size="small"
                  >
                    {districtByState.map((data, index) => (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    id="select"
                    label="City"
                    select
                    error={pointcityError !== "" ? true : false}
                    helperText={pointcityError}
                    className="input_style"
                    value={pointcity}
                    onChange={(e) => setPointCity(e.target.value)}
                    size="small"
                  >
                    {citiersByStates.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </div>

                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Area"
                    type="text"
                    id="text"
                    error={pointAreaError !== "" ? true : false}
                    helperText={pointAreaError}
                    className="input_style"
                    value={pointArea}
                    onChange={(e) =>
                      setPointArea(
                        e.target.value.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                      )
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Pincode"
                    type="text"
                    id="text"
                    error={pointPincodeError !== "" ? true : false}
                    helperText={pointPincodeError}
                    className="input_style"
                    value={pointPincode}
                    onChange={(e) => setPointPincode(e.target.value)}
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      maxLength: 6,
                      minLength: 6,
                    }}
                  />
                </div>

                <div className="dn_input_box">
                  <div className="input_style ">
                    <h6>Point Roles</h6>
                    <Select
                      mode="multiple"
                      status={roleError !== "" ? "error" : ""}
                      maxTagCount="responsive"
                      className="input_style"
                      style={{
                        height: "6.5vh",
                        width: "100%",
                      }}
                      placeholder="Choose Point Types"
                      value={selectedRoles}
                      onSelect={handleSelect}
                      onDeselect={handleDeselect}
                      filterOption={filterOption}
                      size="large"
                    >
                      {Select_next_point.map((data) => (
                        <Option
                          key={data.value}
                          value={data.value}
                          label={data.label}
                        >
                          {data.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="radio_div_container">
                  <div>
                    {selectedRoles.map((data) => (
                      <Chip
                        label={data}
                        variant="outlined"
                        onDelete={() => handleDelete(data)}
                        className="mx-1 my-1"
                      />
                    ))}
                  </div>
                </div>
                {selectedRoles.includes("Delivery_Point") ? (
                  <>
                    <div className="dn_input_box">
                      <div className="input_style ">
                        <h6>Pincodes</h6>
                        {/* {PincodeByDistric.length > 0 ? (
                          <> */}
                        <Select
                          mode="multiple"
                          className="input_style"
                          style={{
                            height: "6.5vh",
                            width: "100%",
                          }}
                          placeholder="Select Pincodes"
                          value={selectedPincodes}
                          onChange={(e) => setSelectedPincodes(e)}
                          filterOption={filterOption}
                          status={deliverypointError !== "" ? "error" : ""}
                          maxTagCount="responsive"
                          size="large"
                        >
                          {PincodeByDistric.sort((a, b) =>
                            a.pincode > b.pincode ? 1 : -1
                          ).map((option) => (
                            <Option
                              key={option.pincode}
                              value={option.pincode}
                              label={option.pincode}
                            >
                              {option.pincode}
                            </Option>
                          ))}
                        </Select>
                        {/* </>
                        ) : (
                          <>
                            <Addpincodes />
                          </>
                        )} */}
                      </div>
                    </div>
                    <div className="radio_div_container">
                      <div>
                        {selectedPincodes.map((data) => (
                          <Chip
                            label={data}
                            variant="outlined"
                            onDelete={() => pincodeDelete(data)}
                            className="mx-1 my-1"
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <h5>Personal Information :</h5>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Name"
                    type="text"
                    id="text"
                    error={nameError !== "" ? true : false}
                    helperText={nameError}
                    className="input_style"
                    value={name}
                    onChange={(e) =>
                      setName(
                        e.target.value.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )
                      )
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Mobile"
                    type="text"
                    id="text"
                    error={mobileError !== "" ? true : false}
                    helperText={mobileError}
                    className="input_style"
                    value={mobile}
                    onChange={(e) => validateMobile(e)}
                    autocomplete="off"
                    inputProps={{
                      maxLength: 10,
                      minLength: 10,
                    }}
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Email"
                    type="Email"
                    id="text"
                    error={emailError !== "" ? true : false}
                    helperText={emailError}
                    className="input_style"
                    value={email}
                    onChange={(e) => validateEmail(e)}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Pancard Number"
                    type="text"
                    id="text"
                    error={pancardError !== "" ? true : false}
                    helperText={pancardError}
                    className="input_style"
                    value={pancard}
                    onChange={(e) => validatePancardno(e)}
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                      minLength: 10,
                    }}
                  />
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Aadhar Card Number"
                    type="text"
                    id="text"
                    error={aadharCardError !== "" ? true : false}
                    helperText={aadharCardError}
                    className="input_style"
                    value={aadharCard}
                    onChange={(e) => validateAadharcard(e)}
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      maxLength: 12,
                      minLength: 12,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
