import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Cookies from "js-cookie";
import utilities from "../../config/utilities";

const initialState = {
    totalcourierList: localStorage.getItem("totalcourierList")
        ? JSON.parse(localStorage.getItem("totalcourierList"))
        : [],
    totalcourierListLoading: true,
    istotalcourierListAvailable: localStorage.getItem("totalcourierList")
        ? true
        : false,
    courierList: localStorage.getItem("courierList")
        ? JSON.parse(localStorage.getItem("courierList"))
        : [],
    courierPickedList: localStorage.getItem("courierList")
        ? JSON.parse(localStorage.getItem("courierList")).filter((item) => item.last_assign_status === "Picked")
        : [],
    courierProcessedList: localStorage.getItem("courierList")
        ? JSON.parse(localStorage.getItem("courierList")).filter((item) => item.last_assign_status === "Processed")
        : [],
    courierAssinedList: localStorage.getItem("courierList")
        ? JSON.parse(localStorage.getItem("courierList")).filter((item) => item.last_assign_status === "Assined")
        : [],
    courierListLoading: true,
    iscourierListAvailable: localStorage.getItem("courierList")
        ? true
        : false,
    courierEcomList: [],
    courierLoading: false,
    isLoading: false,
    ecomIsLoading: false,

};

// to post the courier
export const courierPost = createAsyncThunk(
    "courier/courierpost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "counter-type": "application/json",
                },

            };
            const url = `${Baseurl}/api/v1/courier/new`
            const resp = await axios.post(url, formData, config);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Courier not created")
        }

    }
)

//to fetch courierList
export const getCourierList = createAsyncThunk(
    "courier/courierlist",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/courier/all`;
            const resp = await axios.get(url)
            return resp.data.courier;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 not found")
        }
    }
)

//to update the courier
export const updateCourier = createAsyncThunk(
    "courier/updatecourier",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "counter-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/courier/updateby/${formData._id}`
            const resp = await axios.put(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Courier didn't Updated...")
        }

    }

)

export const get_single_courier = createAsyncThunk(
    "courier/get_single_courier",
    async (id, thunkAPI) => {
        let resp = {
            success: false,
            message: "new pancard",
        };
        try {
            const url = `${Baseurl}/api/v1/courier/get-single-courier/${id}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const get_courier_by_emplayeId = createAsyncThunk(
    "courier/get_courier_by_emplayeId",
    async (id, thunkAPI) => {
        let resp = {
            success: false,
            message: "new pancard",
        };
        try {
            const url = `${Baseurl}/api/v1/courier/get-courier-by-employee-id/${id}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);


const courierListSlice = createSlice({
    name: "courier",
    initialState,
    reducers: {
        setRemoveData(state, action) {
            state.courierList = [];
            state.totalcourierList = [];
            localStorage.setItem("courierList", JSON.stringify(state.courierList));
            localStorage.setItem("totalcourierList", JSON.stringify(state.totalcourierList));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(courierPost.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(courierPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.isLoading = false;
                    state.totalcourierList = [action.payload.courier, ...state.totalcourierList]
                    state.courierList = [action.payload.courier, ...state.courierList]
                    state.courierPickedList = state.courierList.filter((item) => item.last_assign_status === "Picked")
                    state.courierProcessedList = state.courierList.filter((item) => item.last_assign_status === "Processed")
                    state.courierAssinedList = state.courierList.filter((item) => item.last_assign_status === "Assined")
                    localStorage.setItem("courierList", JSON.stringify(state.courierList));
                    localStorage.setItem("totalcourierList", JSON.stringify(state.totalcourierList));
                }
            })
            .addCase(courierPost.rejected, (state) => {
                state.isLoading = true
            })

            .addCase(getCourierList.pending, (state) => {
                state.totalcourierListLoading = true
            })
            .addCase(getCourierList.fulfilled, (state, action) => {
                state.totalcourierList = action.payload
                localStorage.setItem("totalcourierList", JSON.stringify(state.totalcourierList));
                state.istotalcourierListAvailable = true;
                state.totalcourierListLoading = false;
            })
            .addCase(getCourierList.rejected, (state) => {
                state.totalcourierListLoading = true;
            })

            .addCase(get_courier_by_emplayeId.pending, (state) => {
                state.courierListLoading = true
            })
            .addCase(get_courier_by_emplayeId.fulfilled, (state, action) => {
                state.courierList = action.payload.courier
                state.courierPickedList = state.courierList.filter((item) => item.last_assign_status === "Picked")
                state.courierProcessedList = state.courierList.filter((item) => item.last_assign_status === "Processed")
                state.courierAssinedList = state.courierList.filter((item) => item.last_assign_status === "Assined")
                localStorage.setItem("courierList", JSON.stringify(state.courierList));
                state.iscourierListAvailable = true;
                state.courierListLoading = false;
            })
            .addCase(get_courier_by_emplayeId.rejected, (state) => {
                state.courierListLoading = true;
            })

            .addCase(updateCourier.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateCourier.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalcourierList = state.totalcourierList.filter(
                        (courier) => courier._id !== action.payload.courier._id
                    )
                    state.totalcourierList = [action.payload.courier, ...state.totalcourierList]
                    state.courierList = state.courierList.filter(
                        (courier) => courier._id !== action.payload.courier._id
                    )
                    state.courierList = [action.payload.courier, ...state.courierList]
                    const get_token = Cookies.get("login_token");
                    let login_data = ""
                    if (get_token !== undefined) {
                        const get_token_values = (value) => {
                            login_data = value
                        };
                        utilities(get_token, get_token_values);
                    }
                    if (login_data !== "") {
                        state.courierList = state.courierList.filter(
                            (courier) => courier.last_assign_employee_id === login_data._id
                        )
                    }

                    state.courierPickedList = state.courierList.filter((item) => item.last_assign_status === "Picked")
                    state.courierProcessedList = state.courierList.filter((item) => item.last_assign_status === "Processed")
                    state.courierAssinedList = state.courierList.filter((item) => item.last_assign_status === "Assined")
                    localStorage.setItem("courierList", JSON.stringify(state.courierList));
                    localStorage.setItem("totalcourierList", JSON.stringify(state.totalcourierList));
                }
                state.isLoading = false
            })
            .addCase(updateCourier.rejected, (state) => {
                state.isLoading = true
            })
    },
});
export const {
    setRemoveData
} = courierListSlice.actions;
export default courierListSlice.reducer;

