import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
    employeeList: localStorage.getItem("employeeList")
        ? JSON.parse(localStorage.getItem("employeeList"))
        : [],
    employeeListLoading: true,
    isemployeeListAvailable: localStorage.getItem("employeeList")
        ? true
        : false,
    employeeEcomList: [],
    employeeLoading: false,
    isLoading: false,
    ecomIsLoading: false,
    websiteSetting: localStorage.getItem("websiteSetting")
        ? JSON.parse(localStorage.getItem("websiteSetting"))
        : "",
    websiteSettingLoading: false
};
//post the employee
export const employeePost = createAsyncThunk(
    'employee/employeepost',
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                }
            };
            const url = `${Baseurl}/api/v1/employee/new`
            const resp = await axios.post(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Employee is not created ")
        }
    }

)

//to fetch the employees

export const getemployeeList = createAsyncThunk(
    'employee/employeelist',
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/employee/all`
            const resp = await axios.get(url)
            return resp.data.employee
        } catch (error) {
            return thunkAPI.rejectWithValue("404 not found")

        }
    }

)

// to update the employees

export const updateEmployee = createAsyncThunk(
    'employee/updateemployee',
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                }
            };
            const url = `${Baseurl}/api/v1/employee/updateby/${formData._id}`
            const resp = await axios.put(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Employee is not created ")
        }
    }
)


export const validate_Employee_Email = createAsyncThunk(
    "employee/validate_Employee_Email",
    async (email, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/employee/email-verify/${email}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const validate_Employee_Number = createAsyncThunk(
    "employee/validate_Employee_Number",
    async (mobile, thunkAPI) => {
        let resp = {
            success: false,
            message: "new mobile",
        };
        try {
            const url = `${Baseurl}/api/v1/employee/mobile-verify/${mobile}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const validate_Employee_Aadhar = createAsyncThunk(
    "employee/validate_Employee_Aadhar",
    async (aadhar, thunkAPI) => {
        let resp = {
            success: false,
            message: "new aadhar",
        };
        try {
            const url = `${Baseurl}/api/v1/employee/aadharcard-verify/${aadhar}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const validate_Employee_Pancard = createAsyncThunk(
    "employee/validate_Employee_Pancard",
    async (pancard, thunkAPI) => {
        let resp = {
            success: false,
            message: "new pancard",
        };
        try {
            const url = `${Baseurl}/api/v1/employee/pancard-verify/${pancard}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const get_single_employee = createAsyncThunk(
    "employee/get_single_employee",
    async (id, thunkAPI) => {
        let resp = {
            success: false,
            message: "new pancard",
        };
        try {
            const url = `${Baseurl}/api/v1/employee/get-single-employee/${id}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const get_website_settings = createAsyncThunk(
    "employee/get_website_settings",
    async (id, thunkAPI) => {
        let resp = {
            success: false,
            message: "new pancard",
        };
        try {
            const url = `${Baseurl}/api/v1/websitesetting/get-one`;
            const resp = await axios.get(url);
            return resp.data.websiteSetting;
        } catch (error) {
            return error;
        }
    }
);


const employeeListSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        setEmplayRemoveData(state, action) {
            state.employeeList = [];
            localStorage.setItem("employeeList", JSON.stringify(state.employeeList));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(employeePost.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(employeePost.fulfilled, (state, action) => {
                state.isLoading = false;
                state.employeeList = [action.payload.employee, ...state.employeeList]
                localStorage.setItem("employeeList", JSON.stringify(state.employeeList));
            })
            .addCase(employeePost.rejected, (state) => {
                state.isLoading = true;
            })
            .addCase(getemployeeList.pending, (state) => {
                state.employeeListLoading = true
            })
            .addCase(getemployeeList.fulfilled, (state, action) => {
                state.employeeList = action.payload
                localStorage.setItem("employeeList", JSON.stringify(state.employeeList));
                state.employeeListLoading = false
                state.isemployeeListAvailable = true
            })
            .addCase(getemployeeList.rejected, (state) => {
                state.employeeListLoading = true
            })
            .addCase(get_website_settings.pending, (state) => {
                state.websiteSettingLoading = true
            })
            .addCase(get_website_settings.fulfilled, (state, action) => {
                state.websiteSetting = action.payload
                localStorage.setItem("websiteSetting", JSON.stringify(state.websiteSetting));
                state.websiteSettingLoading = false
            })
            .addCase(get_website_settings.rejected, (state) => {
                state.websiteSettingLoading = true
            })

            .addCase(updateEmployee.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateEmployee.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.employeeList = state.employeeList.filter(
                        (employee) => employee._id !== action.payload.employee._id
                    )
                    state.employeeList = [action.payload.employee, ...state.employeeList]
                    localStorage.setItem("employeeList", JSON.stringify(state.employeeList));
                }
                state.isLoading = false
            })
            .addCase(updateEmployee.rejected, (state) => {
                state.isLoading = true
            })

    }
})
export const {
    setEmplayRemoveData
} = employeeListSlice.actions;
export default employeeListSlice.reducer;