import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import Checkbox from "@mui/material/Checkbox";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button, Flex, Modal, message } from "antd";
import { FaEdit, FaBell } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import utilities from "../../config/utilities";
import Cookies from "js-cookie";
import { updateCourier } from "../../redux/courier/courierSlice";
import { CustomTextField } from "../../components/CustomeField";
import { MenuItem, TextareaAutosize } from "@mui/material";
import { transactionPost } from "../../redux/transactions/TransactionSlice";
import { courier_dispatchPost } from "../../redux/courier_dispatch/Courier_Dispatch";
const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ProcessedCouriers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courierProcessedList } = useSelector((store) => store.courier);
  const { employeeList } = useSelector((store) => store.employee);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [fetchData, setFetchData] = useState([]);
  const [filter_data, setfilter_data] = useState([]);
  const [export_data, setexport_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");
  const [loadData, setLoadData] = useState([]);
  const [selectcurier, setSelectcurier] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const [Selected_point, setSelected_point] = useState("");
  const [Selected_point_data, setSelected_point_data] = useState("");
  const [self_number, setSelf_number] = useState("");
  const [rack_number, setRack_number] = useState("");
  const [self_numberError, setSelf_numberError] = useState("");
  const [rack_numberError, setRack_numberError] = useState("");
  const [Selected_pointError, setSelected_pointError] = useState("");
  const [btnPress, setbtnPress] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (btnPress === true) {
      if (Selected_point === "") {
        setSelected_pointError("Required");
      } else {
        setSelected_pointError("");
      }
      // if (
      //   Selected_point_data.point_type === "Hub" ||
      //   Selected_point_data.point_type === "Warehouse"
      // ) {
      //   if (self_number === "") {
      //     setSelf_numberError("Required");
      //   } else {
      //     setSelf_numberError("");
      //   }
      //   if (rack_number === "") {
      //     setRack_numberError("Required");
      //   } else {
      //     setRack_numberError("");
      //   }
      // }
    }
  }, [btnPress, self_number, rack_number, Selected_point, Selected_point_data]);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoadData(courierProcessedList);
  }, [courierProcessedList]);
  useEffect(() => {
    let filter_trans = courierProcessedList;
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return transaction.trackingId.toLowerCase().match(search.toLowerCase());
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date, courierProcessedList]);

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = async () => {
    setbtnPress(true);
    setLoading(true);

    const get_token = Cookies.get("login_token");
    let login_data = "";
    const get_token_values = (value) => {
      login_data = value;
    };
    utilities(get_token, get_token_values);
    const fstpoint_add = `${login_data.pointAddress.houseNumber},${login_data.pointAddress.area},${login_data.pointAddress.city},${login_data.pointAddress.district},${login_data.pointAddress.state},${login_data.pointAddress.country},${login_data.pointAddress.pincode}`;

    const city_up = login_data.pointAddress.city.toLowerCase();

    if (Selected_point_data !== "") {
      const formdata = {
        _id: selectcurier._id,
        last_assign_employee_id: Selected_point_data._id,
        last_assign_shop_name: Selected_point_data.name,
        last_assign_city: Selected_point_data.pointAddress.city,
        last_assign_district: Selected_point_data.pointAddress.district,
        last_assign_state: Selected_point_data.pointAddress.state,
        last_assign_status: "Assined",
        assign_to: [
          ...selectcurier.assign_to,
          {
            employee_id: login_data._id,
            shop_name: login_data.name,
            city: login_data.pointAddress.city,
            district: login_data.pointAddress.district,
            state: login_data.pointAddress.state,
            address: fstpoint_add,
            point_discription: `Shipment has departed from a ${city_up} to ${Selected_point_data.point_type} - ${Selected_point_data.pointAddress.city} INDIA`,
          },
        ],
      };
      const update_courier = await dispatch(updateCourier(formdata));

      if (update_courier.payload.success) {
        const data = update_courier.payload.courier;
        const get_by = (Number(3) / Number(100)) * Number(data.price);
        const commission_amount = Number(get_by).toFixed(2);
        const transactions_Formdata = {
          trackingId: data.trackingId,
          ewcOrderId: data.ewcOrderId,
          Deliveryamount: data.price,
          CoumissionAmount: String(commission_amount),
          CoumissionPercentage: "3",
          courier_point_id: data._id,
        };

        const tran = await dispatch(transactionPost(transactions_Formdata));

        const cou_dispa_formdata = {
          trackingId: data.trackingId,
          ewcOrderId: data.ewcOrderId,
          courier_point_id: data._id,
          depart_employee_id: data.last_assign_employee_id,
          depart_shop_name: data.last_assign_shop_name,
          depart_city: data.last_assign_city,
          depart_district: data.last_assign_district,
          depart_state: data.last_assign_state,
        };

        const cou_disp = await dispatch(
          courier_dispatchPost(cou_dispa_formdata)
        );

        messageApi.open({
          type: "success",
          content: `Courier ${selectcurier.trackingId} departed from a ${login_data.name} ${city_up} done successfully`,
        });

        setbtnPress(false);
        setLoading(false);
        setOpen(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      {contextHolder}

      <Modal
        open={open}
        title={`Departe Courier ${selectcurier.trackingId}`}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleClick}
          >
            Departe
          </Button>,
        ]}
      >
        <>
          <div className="my-4">
            <div className="dn_input_box ">
              <CustomTextField
                id="select"
                label="Next Point"
                select
                className="input_style"
                error={Selected_pointError !== "" ? true : false}
                helperText={Selected_pointError}
                value={Selected_point}
                onChange={(e) => {
                  setSelected_point(e.target.value);

                  if (e.target.value) {
                    const fins_selected_value = employeeList.find(
                      (data) => data.name === e.target.value
                    );
                    setSelected_point_data(fins_selected_value);
                  }
                }}
                size="small"
              >
                {employeeList.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.shop} - {data.point_type} - {data.pointAddress.city}
                  </MenuItem>
                ))}
              </CustomTextField>
            </div>
          </div>
        </>
      </Modal>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Search Processed Courier</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="transaction_header">
          <Input
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
            }}
          />
          <TablePagination
            component="div"
            count={0}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 100, 500]}
            labelRowsPerPage="Rows:"
            labelDisplayedRows={() => null}
            SelectProps={{
              inputProps: { "aria-label": "rows" },
              native: true,
            }}
            ActionsComponent={() => null}
          />
        </div>
        <div className="letter_content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} className="customScrollbar">
              <Table
                sx={{ minWidth: 700, border: "none" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ border: "none" }}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Address
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      City
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      State
                    </StyledTableCell>

                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter_data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
                    .map((courier, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ border: "none" }}
                          width={50}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          width={250}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {courier.recipient_name}
                        </StyledTableCell>
                        <StyledTableCell
                          width={200}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {courier.recipient_address.address}
                        </StyledTableCell>
                        <StyledTableCell
                          width={150}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {courier.recipient_address.city}
                        </StyledTableCell>
                        <StyledTableCell
                          width={150}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {courier.recipient_address.state}
                        </StyledTableCell>
                        <StyledTableCell
                          width={100}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          <div className="d-flex m-auto my-2">
                            <Button
                              onClick={() => {
                                setSelectcurier(courier);
                                setOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                              className="edit_button"
                            >
                              DEPARTE
                            </Button>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 100, 500]}
              component="div"
              count={filter_data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ProcessedCouriers;
