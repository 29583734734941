import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
// import { courierPost } from "../../redux/courier/courierSlice";
import { CustomTextField } from "../../components/CustomeField";
import { updateCourier } from "../../redux/courier/courierSlice";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem } from "@mui/material";
export const EditCourier = () => {
  const [sendername, setSenderName] = useState("");
  const [sendermobile, setSenderMobile] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderZipCode, setSenderZipCode] = useState("");
  const [recipientname, setRecipientName] = useState("");
  const [recipientmobile, setRecipientMobile] = useState("");
  const [recipientCity, setRecipientCity] = useState("");
  const [recipientState, setRecipientState] = useState("");
  const [recipientZipCode, setRecipientZipCode] = useState("");
  const [recipientaltmobile, setRecipientAltMobile] = useState("");
  const [description_of_couriers, setDescription_of_couriers] = useState("");
  const [dimenstions, setDimenstions] = useState("");
  const [handling_instruction, setHandling_instruction] = useState("");
  const [prefered_delivery_date, setPrefered_delivery_date] = useState("");
  const [prefered_delivery_time, setPrefered_delivery_time] = useState("");
  const [pickup_date, setPickup_date] = useState("");
  const [pickup_time, setPickup_time] = useState("");
  const [self_number, setSelf_number] = useState("");
  const [rack_number, setRack_number] = useState("");
  const [payment_method, setPaymentMethod] = useState("");
  const [payment_reference_id, setPayment_Refrence_Id] = useState("");
  // const [tracking_Id, setTracking_Id] = useState("");
  const [billing_Address, setBilling_Address] = useState("");
  const [date, setDate] = useState("");
  const [employee_id, set_Employee_Id] = useState("");
  const [shop, setShop] = useState("");
  const [assign_city, setAssgn_City] = useState("");
  const [assign_address, setAssgn_Address] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");

  const dispatch = useDispatch();
  const { courierList } = useSelector((store) => store.courier);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getCourier = params.id;
    const updateCourier = courierList.find(
      (courier) => courier._id === getCourier
    );
    setSenderName(updateCourier.sender_name);
    setSenderMobile(updateCourier.sender_phoneNumber);
    setSenderCity(updateCourier.sender_address.city);
    setSenderState(updateCourier.sender_address.state);
    setSenderZipCode(updateCourier.sender_address.zip_code);
    setRecipientName(updateCourier.recipient_name);
    setRecipientMobile(updateCourier.recipient_phonenumber);
    setRecipientCity(updateCourier.recipient_address.city);
    setRecipientState(updateCourier.recipient_address.state);
    setRecipientZipCode(updateCourier.recipient_address.zip_code);
    setRecipientAltMobile(updateCourier.recipient_alt_phoneNumber);
    setDescription_of_couriers(updateCourier.description_of_couriers);
    setDimenstions(updateCourier.dimensions);
    setHandling_instruction(updateCourier.handling_instruction);
    setPrefered_delivery_time(updateCourier.preferred_delivery_time);
    setPrefered_delivery_date(updateCourier.preferred_delivery_date);
    setPickup_date(updateCourier.pickup_date);
    setPickup_time(updateCourier.pickup_time);
    setSelf_number(updateCourier.self_number);
    setRack_number(updateCourier.rack_numbers);
    setPaymentMethod(updateCourier.payment_method);
    setPayment_Refrence_Id(updateCourier.payment_reference_id);
    setBilling_Address(updateCourier.billing_address);
    setWeight(updateCourier.weight);
    setPrice(updateCourier.price);
  }, []);
  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      _id: params.id,
      sender_name: sendername,
      sender_phoneNumber: sendermobile,
      sender_address: {
        city: senderCity,
        state: senderState,
        zip_code: senderZipCode,
      },
      recipient_name: recipientname,
      recipient_phonenumber: recipientmobile,
      recipient_alt_phoneNumber: recipientaltmobile,
      recipient_address: {
        city: recipientCity,
        state: recipientState,
        zip_code: recipientZipCode,
      },
      description_of_couriers: description_of_couriers,
      dimensions: dimenstions,
      handling_instruction: handling_instruction,
      rack_number: rack_number,
      self_number: self_number,
      preferred_delivery_date: prefered_delivery_date,
      preferred_delivery_time: prefered_delivery_time,
      pickup_date: pickup_date,
      pickup_time: pickup_time,
      payment_method: payment_method,
      payment_reference_id: payment_reference_id,
      billing_address: billing_Address,
      // assign_to: [
      //   {
      //     employee_id: employee_id,
      //     shop_name: shop,
      //     city: assign_city,
      //     address: assign_address,
      //     date: date
      //   }
      // ],
      weight: weight,
      price: price,
    };
    const dataPost = await dispatch(updateCourier(formData));
    if (dataPost.payload.success) {
      alert("Courier Data has been Updated");
      setSenderName("");
      setSenderMobile("");
      setSenderCity("");
      setSenderState("");
      setSenderZipCode("");
      setRecipientName("");
      setRecipientMobile("");
      setRecipientCity("");
      setRecipientState("");
      setRecipientZipCode("");
      setRecipientAltMobile("");
      setDescription_of_couriers("");
      setDimenstions("");
      setHandling_instruction("");
      setPickup_date("");
      setPickup_time("");
      setPrefered_delivery_date("");
      setPrefered_delivery_time("");
      setSelf_number("");
      setRack_number("");
      setPaymentMethod("");
      setPayment_Refrence_Id("");
      setBilling_Address("");
      setDate("");
      set_Employee_Id("");
      setShop("");
      setAssgn_City("");
      setAssgn_Address("");
      setWeight("");
      setPrice("");
      navigate("/admin/courierlisting");
    } else {
      alert("courier data not updated ");
    }
  };
  const SelectEthinicity = [
    {
      id: 1,
      name: "White",
    },
    {
      id: 2,
      name: "Black or African American ",
    },
    {
      id: 3,
      name: "Asian",
    },
    {
      id: 4,
      name: "Hispanic or Latino",
    },
    {
      id: 5,
      name: "Other",
    },
  ];
  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Update Courier Details</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div>
              <h5>Sender Details</h5>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                    label="Name"
                    type="text"
                    id="text"
                    className="input_style"
                    value={sendername}
                    onChange={(e) => setSenderName(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Mobile No"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={sendermobile}
                    onChange={(e) =>
                      setSenderMobile(e.target.value.replace((/\D/g, "")))
                    }
                    autocomplete="off"
                    inputProps={{
                      maxLength: 10,
                      minLength: 10,
                    }}
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="City"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={senderCity}
                    onChange={(e) => setSenderCity(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="State"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={senderState}
                    onChange={(e) => setSenderState(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Zip Code"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={senderZipCode}
                    onChange={(e) => setSenderZipCode(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
              </div>
              <h5>Recipient Details:</h5>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    label="Name"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={recipientname}
                    onChange={(e) => setRecipientName(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Mobile Number"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={recipientmobile}
                    onChange={(e) =>
                      setRecipientMobile(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                      minLength: 10,
                    }}
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="City"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={recipientCity}
                    onChange={(e) => setRecipientCity(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="State"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={recipientState}
                    onChange={(e) => setRecipientState(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Zip Code"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={recipientZipCode}
                    onChange={(e) => setRecipientZipCode(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Alternative Mobile Number"
                    type="text"
                    id="text"
                    inputProps={{
                      maxLength: 10,
                      minLength: 10,
                    }}
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={recipientaltmobile}
                    onChange={(e) =>
                      setRecipientAltMobile(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
              </div>

              <h5>Courier Details</h5>

              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    label="description of Couriers"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={description_of_couriers}
                    onChange={(e) => setDescription_of_couriers(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Price"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Dimensions h*w*d"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={dimenstions}
                    onChange={(e) => setDimenstions(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Weight"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Handling instructions"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={handling_instruction}
                    onChange={(e) => setHandling_instruction(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Prefered delivery date"
                    type="date"
                    id="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={prefered_delivery_date}
                    onChange={(e) => {
                      prefered_delivery_date(e.target.value);
                    }}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Prefered delivery time"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={prefered_delivery_time}
                    onChange={(e) => setPrefered_delivery_time(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Pickup date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={pickup_date}
                    onChange={(e) => setPickup_date(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Pickup time"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={pickup_time}
                    onChange={(e) => setPickup_time(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                {/* <div className="dn_input_box">
                  <CustomTextField
                    label="Payment Method"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={payment_method}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2">
                  </p>
                </div> */}
                {/* dropdown */}
                <div className="dn_input_box">
                  <CustomTextField
                    id="select"
                    label="Payment Method"
                    select
                    className="input_style"
                    // error={genderError !== "" ? true : false}
                    // helperText={genderError}
                    value={payment_method}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    size="small"
                  >
                    {SelectEthinicity.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Payment Reference Id"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={payment_reference_id}
                    onChange={(e) => setPayment_Refrence_Id(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Self Number"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={self_number}
                    onChange={(e) => setSelf_number(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Rack Number"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={rack_number}
                    onChange={(e) => setRack_number(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <textarea
                    name="textarea"
                    placeholder="Billing address"
                    cols={50}
                    rows={4}
                    className="text_Area_contentrow"
                    value={billing_Address}
                    onChange={(e) => setBilling_Address(e.target.value)}
                    autocomplete="off"
                  ></textarea>
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
              </div>

              <h5>Assign To :</h5>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <CustomTextField
                    label="Employee Id"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}
                    value={employee_id}
                    onChange={(e) => set_Employee_Id(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Shop Name"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={shop}
                    onChange={(e) => setShop(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="City"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={assign_city}
                    onChange={(e) => setAssgn_City(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Addresss"
                    type="text"
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={assign_address}
                    onChange={(e) => setAssgn_Address(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
                <div className="dn_input_box">
                  <CustomTextField
                    label="Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="text"
                    className="input_style"
                    // error={nameError !== "" ? true : false}
                    // helperText={nameError}

                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                  <p style={{ color: "red" }} className="mt-2"></p>
                </div>
              </div>
            </div>
            <div className="card-footer ">
              {price != "" &&
              weight !== "" &&
              sendername !== "" &&
              sendermobile !== "" &&
              senderCity !== "" &&
              senderState !== "" &&
              senderZipCode !== "" &&
              recipientname !== "" &&
              recipientmobile !== "" &&
              recipientCity !== "" &&
              recipientState !== "" &&
              recipientZipCode !== "" &&
              recipientaltmobile !== "" &&
              description_of_couriers !== "" &&
              dimenstions !== "" &&
              handling_instruction !== "" &&
              prefered_delivery_time !== "" &&
              prefered_delivery_date !== "" &&
              pickup_date !== "" &&
              pickup_time !== "" &&
              payment_method !== "" &&
              payment_reference_id !== "" &&
              billing_Address !== "" &&
              weight !== "" &&
              price !== "" ? (
                <button className="btn btn-primary" type="submit">
                  Update Courier
                </button>
              ) : (
                <button className="btn btn-primary" type="submit" disabled>
                  Update Courier
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
