import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
    transaction: localStorage.getItem("transaction")
        ? JSON.parse(localStorage.getItem("transaction"))
        : [],
    transactionLoading: true,
    transactionLoadingAvailable: localStorage.getItem("transaction")
        ? true
        : false,
    isLoading: false,
    total_money: 0,
};

export const transactionPost = createAsyncThunk(
    "transaction/transactionPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "counter-type": "application/json",
                },

            };
            const url = `${Baseurl}/api/v1/transactions/new`
            const resp = await axios.post(url, formData, config);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Courier not created")
        }

    }
)

export const getTransactionPost = createAsyncThunk(
    "transaction/getTransactionPost",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/transactions/all`;
            const resp = await axios.get(url)
            return resp.data.transaction;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 not found")
        }
    }
)


const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        SettransactionData(state, action) {
            state.transaction = [];
            state.total_money = 0;
            localStorage.setItem("transaction", JSON.stringify(state.transaction));
        },
        get_total_amount(state, action) {
            state.total_money = 0
            for (let index = 0; index < state.transaction.length; index++) {
                state.total_money = state.total_money + Number(state.transaction[index].CoumissionAmount)
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(transactionPost.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(transactionPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.isLoading = false;
                    state.transaction = [action.payload.transaction, ...state.transaction]
                    state.total_money = 0
                    for (let index = 0; index < state.transaction.length; index++) {
                        state.total_money = state.total_money + Number(state.transaction[index].CoumissionAmount)
                    }
                    localStorage.setItem("transaction", JSON.stringify(state.transaction));
                }
            })
            .addCase(transactionPost.rejected, (state) => {
                state.isLoading = true
            })

            .addCase(getTransactionPost.pending, (state) => {
                state.transactionLoading = true
            })
            .addCase(getTransactionPost.fulfilled, (state, action) => {
                state.transaction = action.payload
                state.total_money = 0
                for (let index = 0; index < state.transaction.length; index++) {
                    state.total_money = state.total_money + Number(state.transaction[index].CoumissionAmount)
                }
                localStorage.setItem("transaction", JSON.stringify(state.transaction));
                state.transactionLoadingAvailable = true;
                state.transactionLoading = false;
            })
            .addCase(getTransactionPost.rejected, (state) => {
                state.transactionLoading = true;
            })

    },
});
export const {
    SettransactionData,
    get_total_amount
} = transactionSlice.actions;
export default transactionSlice.reducer;

