import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import Checkbox from "@mui/material/Checkbox";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button, Flex, Modal, message } from "antd";
import { FaEdit, FaBell } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { get_single_courier } from "../../redux/courier/courierSlice";
const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const ListCourier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchError, setSearchError] = useState("");

  const [buttonPress, setButtonPress] = useState(false);

  const orderTrackingPress = async () => {
    setButtonPress(true);
    setSearchError("");
    if (search !== "") {
      const get_single = await dispatch(get_single_courier(search));
      if (get_single.payload.success) {
        const courier = get_single.payload.courier;
        navigate(`/admin/courier-details/${courier.trackingId}`);
      } else {
        setSearchError("Courier not found with this Courier Id");
      }
    } else {
      setSearchError("Please enter Courier Id");
    }
  };

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Search Courier</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="transaction_header">
          <Input
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
            }}
            status={searchError !== "" ? "error" : ""}
          />

          <Button type="primary" onClick={() => orderTrackingPress()}>
            Track Courier
          </Button>
        </div>
      </div>
    </>
  );
};
