import React from "react";

const Dashboard_Footer = () => {
  return (
    <>
      <div className="dashboard_footer_container desktop_version_footer">
        <span>
          Powered by EwCourier | Design by Dexterous Technology © 2024 All
          Rights Reserved.
        </span>
      </div>
      {/* <div className="dashboard_footer_container mobile_version_footer">
        <span>
          Powered by EwCourier | Design by Dexterous Technology
          <br />  © 2024 All Rights Reserved.
        </span>
      </div> */}
    </>
  );
};

export default Dashboard_Footer;
