
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from './pages/Login';
import Dashboard_Layout from './components/dashboard/Dashboard_Layout';
import Dashboard from './pages/Dashboard';
import { AddCourier } from "./pages/courier_management/AddCourier";
import { EditCourier } from "./pages/courier_management/EditCourier";
import { ListCourier } from "./pages/courier_management/ListCourier";
import { AddEmployee } from "./pages/admin_managementt/AddEmployee";
import { EmployeeList } from "./pages/admin_managementt/EmployeeList";
import { EditEmployee } from "./pages/admin_managementt/EditEmployee";
import { get_courier_by_emplayeId, getCourierList } from "./redux/courier/courierSlice";
import { get_website_settings, getemployeeList } from "./redux/employee/employeeSlice";
import Add_Admin from "./pages/admin_management/Add_Admin";
import { Single_Courier_Details } from "./pages/courier_management/Single_Courier_Details";
import PickedCourier from "./pages/courier_management/PickedCourier";
import AssinedCouriers from "./pages/courier_management/AssinedCouriers";
import ProcessedCouriers from "./pages/courier_management/ProcessedCouriers";
import utilities from "./config/utilities";
import Cookies from "js-cookie";
import { getCourier_dispatchList } from "./redux/courier_dispatch/Courier_Dispatch";
import { getTransactionPost } from "./redux/transactions/TransactionSlice";
import List_Transactions from "./pages/transaction/List_Transactions";
import List_courier_dispatch from "./pages/courier_dispatch/List_courier_dispatch";
import MainCourierPointAdd from "./pages/admin_managementt/MainCourierPointAdd";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch()

  const {
    courierListLoading,
    courierList,
    iscourierListAvailable,
    totalcourierListLoading,
    totalcourierList,
    istotalcourierListAvailable,
  } = useSelector((state) => state.courier);
  const {
    employeeListLoading,
    employeeList,
    isemployeeListAvailable
  } = useSelector((state) => state.employee);
  const {
    courier_dispatch_list,
    courier_dispatch_listLoading,
    iscourier_dispatch_listAvailable
  } = useSelector((state) => state.courier_dispatch);
  const {
    transaction,
    transactionLoading,
    transactionLoadingAvailable
  } = useSelector((state) => state.transaction);

  useEffect(() => {

  }, [transaction])


  const [Login_data, setLogin_data] = useState("");
  const get_token = Cookies.get("login_token");
  useEffect(() => {
    const get_data = () => {
      if (get_token !== undefined) {
        const get_token_values = (value) => {
          setLogin_data(value);
        };
        utilities(get_token, get_token_values);
      }
    }
    get_data()
  }, [get_token]);
  useEffect(() => {
    if (Login_data !== "") {
      if (!iscourierListAvailable || courierList.length === 0) {
        dispatch(get_courier_by_emplayeId(Login_data._id))
      }
    }
    if (!istotalcourierListAvailable || totalcourierList.length === 0) {
      dispatch(getCourierList())
    }
    if (!isemployeeListAvailable || employeeList.length === 0) {
      dispatch(getemployeeList())
    }
    if (!iscourier_dispatch_listAvailable || courier_dispatch_list.length === 0) {
      dispatch(getCourier_dispatchList())
    }
    if (!transactionLoadingAvailable || transaction.length === 0) {
      dispatch(getTransactionPost())
    }
  }, [Login_data])
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (Login_data !== "") {
        if (courierListLoading) {
          dispatch(get_courier_by_emplayeId(Login_data._id))
        }
      }

      if (totalcourierListLoading) {
        dispatch(getCourierList())
      }
      if (employeeListLoading) {
        dispatch(getemployeeList())
      }
      if (courier_dispatch_listLoading) {
        dispatch(getCourier_dispatchList())
      }
      if (transactionLoading) {
        dispatch(getTransactionPost())
      }
      dispatch(get_website_settings())

    }, 2000);
  }, [Login_data]);
  useEffect(() => {
    window.scrollTo(0, 0);

  }, [pathname]);
  return null;
}

function App() {

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route path="/admin" exact element={<Dashboard_Layout />}>
            <Route path="/admin" index element={<Dashboard />} />
            <Route path="/admin/add_admin" element={<Add_Admin />} />

            {/* admin management */}
            <Route path="/admin/add-courier-point" element={<AddEmployee />} />
            <Route path="/admin/list-courier-point" element={<EmployeeList />} />
            <Route path="/admin/update-courier-point/:id" element={<EditEmployee />} />

            {/* courier management */}
            <Route path="/admin/courierlisting" element={<ListCourier />} />

            <Route path="/admin/editcourier/:id" element={<EditCourier />} />
            <Route path="/admin/courier-details/:tracking_id" element={<Single_Courier_Details />} />

            <Route path="/admin/transactions-history" element={<List_Transactions />} />
            <Route path="/admin/courier-dispatch" element={<List_courier_dispatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
